<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技携手华鑫证券，共探大模型时代投资赋能
              </p>
              <div class="fst-italic mb-2">2024年3月20日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >华鑫证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/75/01-李佐凡.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近年来，大模型技术席卷全球，其应用生态的建设与探索被金融行业越来越关注。3月13日，非凸科技联合华鑫证券举办了一场“LLM时代的投资赋能”主题分享活动，与管理人、资方共同探讨了大模型技术在投资领域的应用优势与发展前景。
                  </p>
                  <p>
                    会上，非凸科技联合创始人兼CTO李佐凡致辞时表示，对于金融从业者，我们更关注的是如何利用AI模型优化交易策略，如何运用强化学习、时序模型等工具提升算法性能。感谢华鑫证券对此次活动的大力支持，未来，我们希望携手更多行业伙伴在数智交易生态建设上创造更多可能性。大模型时代，非凸科技将会持续专注超强算力的提升，不断探索以大模型为代表的AI技术研发和智能交易工具，为金融行业数智化升级贡献力量。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/75/02-马海源.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    致诚卓远副总经理马海源与嘉宾分享了主题“技术调优与价值创造”。他表示，量化交易将投资交易经验数字化并降低成本，而人工智能的发展则为量化交易提供了更多可能性。科技助力量化，是智能增强而非人工替代，最终还是靠使用技术的人赢得市场先机。技术变革日新月异，我们不仅要对前沿技术保持敏感，还要进行创意的多元与集成，以及持续的工程调优。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/75/03-崔宏禹.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    作为“以金融科技引领业务发展”为核心战略的华鑫证券，始终以客户核心需求为中心，致力于为客户提供交易、投融资、财富管理等高品质的服务和产品。由华鑫证券自研的特色交易柜台、特色行情等全链路解决方案，满足了机构客户在数智交易领域低延时、高并发的交易需求。华鑫证券首席数据官兼信息技术部总经理崔宏禹详细介绍了大模型的发展进程及场景应用。他表示，大模型的应用目的是为了取代实质性能，是针对不同场景的业务流创新，不是技术的简单揉合。对于金融机构来说，将大模型技术应用到业务中非常重要。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/75/04-杜放.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，重庆信托权益资本部总经理杜放进行了本轮量化波动的后续展望，以及分析了对信托私募配置的影响。他表示，2024年，“经济弱复苏&流动性部分缓减”可能是全年市场的主线，从指数绝对点位、上市公司业绩底部等角度来看，当前权益市场具备一定配置价值，市场机会大于风险。在私募基金领域，希望携手行业伙伴为投资者创造更稳健和更有价值的投资组合，发现更多有配置机会的资产和策略。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/75/05-圆桌.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    虽然，大模型的未来发展仍有很多未知与不确定性，但我们看到了从最初的全民热情高涨到当下的理性回归。圆桌环节，致诚卓远副总经理马海源、理石执行董事兼总裁周理、鲲洋量化投资负责人郭翰、通登资产合伙人兼CTO吴昭就“LLM在投资中的运用”展开了交流并发表了多元见解。吴昭表示，在低频交易方面，大模型技术作为数据处理和提供交易信号的工具，目前来看还是比较成功，但在高频交易方面，大模型的表现就相对慢了些。郭翰表示，量化投资行业对于GPT的应用程度尚未广泛，但在数据管理、风险管理方面仍有待挖掘的潜力。周理表示，未来人工智能的发展会面临一些伦理争议，尤其在隐私保护和数据泄露方面，此外，人工智能的发展也需要解决硬件和大模型基础设施的差距，以应对未来挑战。对于未来市场的展望，马海源表示，相信量化投资在经济周期中能够为投资人带来更多价值。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News75",
};
</script>

<style></style>
